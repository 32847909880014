const obra = [
  {
    image:
      "https://img.freepik.com/free-photo/partial-shot-stadium-displaying-roof-large-monitor-top-seating-rows-chairs_181624-1573.jpg?t=st=1733178567~exp=1733182167~hmac=a36a68c761520635a9a182f25ec4db12d0f3158ec99f68726ffba30e1c696320&w=1380",
    title: "Serviço 1",
    description: "Informar a descricao do Serviço 1 resumido.",
  },
  {
    image:
      "https://img.freepik.com/free-photo/partial-shot-stadium-displaying-roof-large-monitor-top-seating-rows-chairs_181624-1573.jpg?t=st=1733178567~exp=1733182167~hmac=a36a68c761520635a9a182f25ec4db12d0f3158ec99f68726ffba30e1c696320&w=1380",
    title: "Serviço 2",
    description: "Esta é uma descrição do card.",
  },
  {
    image:
      "https://img.freepik.com/free-photo/partial-shot-stadium-displaying-roof-large-monitor-top-seating-rows-chairs_181624-1573.jpg?t=st=1733178567~exp=1733182167~hmac=a36a68c761520635a9a182f25ec4db12d0f3158ec99f68726ffba30e1c696320&w=1380",
    title: "Serviço 3",
    description: "Esta é uma descrição do card.",
  },
  {
    image:
      "https://img.freepik.com/free-photo/partial-shot-stadium-displaying-roof-large-monitor-top-seating-rows-chairs_181624-1573.jpg?t=st=1733178567~exp=1733182167~hmac=a36a68c761520635a9a182f25ec4db12d0f3158ec99f68726ffba30e1c696320&w=1380",
    title: "Serviço 4",
    description: "Esta é uma descrição do card.",
  }
];

export default obra;

import React, { Component } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Section from "./components/Section";
import dummyText from "./DummyText";
import Sobre from "./components/Sobre";
import Servicos from "./components/Servicos";
import Footer from "./components/Footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "./images/logo.jpeg"

class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar />
        <Section
          subtitle={dummyText}
          dark={false}
          id="sobre"
          Component={Sobre}
        />
        <Section
          title="Serviços"
          subtitle={dummyText}
          dark={false}
          id="servicos"
          Component={Servicos}
        />
        <Section title="Obras" subtitle={dummyText} dark={true} id="obras" />
        <Section
          title="Orçamento"
          subtitle={dummyText}
          dark={false}
          id="orcamento"
        />
        <Section
          title="Fale Conosco"
          subtitle={dummyText}
          dark={true}
          id="contato"
        />
        <FloatingWhatsApp
          phoneNumber="+5521969293337"
          accountName="Nivele"
          allowEsc
          allowClickAway
          notification
          notificationSound
          chatMessage="Olá somos a nivele mais"
          statusMessage="Disponível"
          avatar={logo}
          placeholder="Digite sua mensagem"
        />
        <Footer></Footer>
      </div>
    );
  }
}

export default App;

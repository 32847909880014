import React from 'react';

// Componente Card
const Card = ({ image, title, description }) => {
  return (
    <div style={styles.card}>
      <img src={image} alt={title} style={styles.image} />
      <div style={styles.content}>
        <h2 style={styles.title}>{title}</h2>
        <p style={styles.description}>{description}</p>
      </div>
    </div>
  );
};

const styles = {
    card: {
      border: '1px solid #ccc',
      borderRadius: '8px',
      overflow: 'hidden',
      width: '300px',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
      margin: '16px',
    },
    image: {
      width: '100%',
      height: '200px',
      objectFit: 'cover',
    },
    content: {
      padding: '16px',
    },
    title: {
      fontSize: '1.5em',
      margin: '0 0 10px',
    },
    description: {
      color: '#555',
    },
  };
  
  
  export default Card;
import React from 'react';

function Footer() {
  return (
    <footer className='footer'>
      <h2>Nivele Mais</h2>
      <p>
        ©2024 Nivele — Desenvolvido por Marcos Cunha
        {' '}
        <a
          href='https://www.linkedin.com/in/marcosdavid/'
          rel='noreferrer'
          target='_blank'
        >
        </a>
      </p>
    </footer>
  );
}

export default Footer;
import React from "react";
import Banner from "../images/banner.jpg"

const Sobre = () => {
  return (
    <div style={{ width: '100%', height: '400px' }}>
      <img 
        src={Banner} 
        alt="Nivele" 
        style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
      />
    </div>
  );
};

export default Sobre;

import React from "react";
import Card from "./Card";
import Obra from "../data/obras"

// Componente principal
const Servicos = () => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
    >
      {Obra.map((o)=>(
        <Card image={o.image} title={o.title} description={o.description} />
      ))}
    </div>
  );
};

export default Servicos;

import React from "react";

export default function Section({ title, subtitle, dark, id, Component }) {
  return (
    <div className={"section" + (dark ? " section-dark" : "")}>
      <div className="section-content" id={id}>
        <h1>{title && title}</h1>
        {Component && <Component />} {/* Renderiza o componente filho, se fornecido */}
      </div>
    </div>
  );
}
import React, { Component } from "react";
import logo from "../images/logo_menu.png"
import { Link, animateScroll as scroll } from "react-scroll";

export default class Navbar extends Component {
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <nav className="nav" id="navbar">
        <div className="nav-content">
          <img
            src={logo}
            className="nav-logo"
            alt="Logo"
            onClick={this.scrollToTop}
          />
          <ul className="nav-items">
            <li className="nav-item">
              <Link
                activeClass="active"
                to="sobre"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Nivele Mais
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="servicos"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Serviços
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="obras"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Obras
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="orcamento"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Orçamento
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="contato"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Fale Conosco
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}